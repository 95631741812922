import React from 'react'
import Helmet from 'react-helmet'
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <Helmet title="NOT FOUND" />
    <section className="content">
      <h1>NOT FOUND</h1>
      <p>You just hit a page that doesn&#39;t exist... Maybe time for a swim?</p>
    </section>
  </Layout>
)

export default NotFoundPage
